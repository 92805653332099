import logo from './logo.svg';
import './App.css';
import { FaCrosshairs } from 'react-icons/fa';
import { useState, useEffect } from 'react';
import axios from 'axios';
function App() {
    const [trends, setTrends] = useState([]);
    const [woeid, setWoeid] = useState('23424908');
    const apiBaseUrl = `https://twt-api.appng.co`;
    //const apiBaseUrl = `http://localhost:7000`;
    useEffect(() => getTrends(), [woeid]);
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="logo" alt="Twitter" />
                <h3>Twitter Trends</h3>
            </header>
            <div className="menu">
                <select name="trending-place" onChange={(e) => setWoeid(e.target.value)}>
                    <option value="23424908">Nigeria</option>
                    <option value="1">Worldwide</option>
                    <option value="23424848">India</option>
                    <option value="1398823">Lagos, NG</option>
                    <option value="2459115">New York, US</option>
                    <option value="2442047">Los Angeles, US</option>
                    <option value="2295411">Mumbai, India</option>
                    <option value="1105779">Sydney, AU</option>
                </select>
                <div className="location" onClick={handleLocation}>
                    <FaCrosshairs />
                </div>
            </div>
            <div className="content">{listTrends()}</div>
        </div>
    );

    function handleLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    axios
                        .get(`${apiBaseUrl}/api/v1/twitter-trends/nearme`, {
                            params: {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                            },
                        })
                        .then((response) => {
                            setWoeid(response.data[0].woeid);
                        })
                        .catch((error) => console.error(error.message));
                },
                (error) => {
                    console.error(error.message);
                }
            );
        } else {
            alert('Can not get your geolocation');
        }
    }
    function listTrends() {
        return (
            <ul>
                {trends.map((trend, index) => {
                    return (
                        <li key={index}>
                            <a href={trend.url}>{trend.name}</a>
                            {trend.tweet_volume && <span className="tweet_volume">{trend.tweet_volume}</span>}
                        </li>
                    );
                })}
            </ul>
        );
    }
    function getTrends() {
        axios
            .get(`${apiBaseUrl}/api/v1/twitter-trends/${woeid}`)
            .then((response) => {
                setTrends(response.data.trends);
            })
            .catch((error) => console.error(error.message));
    }
}

export default App;
